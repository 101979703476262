.experience-card-contribution{
    border: #ff8657 2px solid;
    padding: 2% 2% 2% 2%;
    border-radius: 20px;
  }

.experience-date{
    font-size: 0.9rem;
    font-weight: 600;
    color: #6b6b6b;
  }