.project-card-right {
  margin-top: 5%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #ffc371; */
}

.project-card-right-header,.links-area-right {
  text-align: right;
}

.project-card-right-description{
  text-align: right;
}

.links-area-right ul li {
  background-color: aqua;
  list-style-type: none;
  display: inline-block;
  padding: 10px 50px 0px 0px;
}

.project-card-images {
  display: flex;
  justify-content: center;
}

.project-images-list li {
  list-style-type: none;
  display: inline-block;
  padding: 0px 20px 0px 20px;; 
}