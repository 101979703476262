@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap'); */

/* Styling for the main introduction area */

/* #introduction {
  background: linear-gradient(to right, #a9c9ff, #ffbbec);
  animation: change 10s ease-in-out infinite;
  background-size: 400% 400%;
  width: 100%;
  height: 100vh;
} */

#introduction{
  height: 100vh;
}

@keyframes change {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

* {
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

/* Styling for the main introduction area */
#social-links ul li a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

::-webkit-scrollbar {
  display: none;
}

/* Navigation Bar Styling */
#nav-bar {
  display: flex;
  justify-content: center;
}

nav ul li {
  list-style-type: none;
  display: inline-block;
  padding: 10px 50px 0px 50px;
}

nav ul li a {
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: bold;
}

nav ul li a:hover {
  color: #ffc371;
  transition: 0.3s;
}

/* Styling for my name */
#name-header {
  color: black;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  padding-top: 100px;
}

#avatar-image{
  position: absolute;
  top: 65%;
  right: 0;
  z-index: -1;
}

#info-header {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 0px;
}

.scroll-icon {
  display: flex;
  justify-content: center;
  padding-top: 2%;
}

#header-image {
  display: flex;
  justify-content: center;
  color: black;
  margin-left: auto;
  margin-right: auto;
  width: 37.5%;
  background: whitesmoke;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

#information {
  color: black;
  padding: 1.5% 0% 5% 0%;
  /*background: #fff;*/
  /* background: rgb(250, 250, 250, 0.8); */
  /* background: rgb(250, 250, 250); */
  background: whitesmoke;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

/* Styling for the SOCIALS area */
#socials {
  display: flex;
  justify-content: center;
}

#resume-title{
  text-align: center;
  color: black;
}

.resume-title-class{
  text-decoration: none;
}

#social-links ul li {
  list-style-type: none;
  display: inline-block;
  padding: 0px 10px 0px 100px;
}

/* Styling for the RESUME area */
#resume {
  display: flex;
  justify-content: center;
  padding-top: 5%;
  margin-top: 5%;
}

#projects {
  padding-top: 1%;
}

#socials {
  padding-top: 2%;
  padding-bottom: 4%;
}

#background-video{
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  height: 120vh;
}

.line-class{
  border: 1px solid rgb(129, 129, 129);
  width: 100%;
  margin-top: 100px;
}