.project-card-left {
  margin-top: 5%;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #ffc371; */
}

.project-links {
  margin-left: 0%;
  position: relative;
  right: 2.8%
}

.links-area {
  text-align: left;
}

.links-area ul li {
  text-decoration: none;
  list-style-type: none;
  display: inline-block;
  padding: 10px 50px 0px 0px;
}

.tech-links-area {
  text-align: left;
}

.tech-links-area ul li {
  text-decoration: none;
  list-style-type: none;
  display: inline-block;
  padding-right: 5%;
}

.project-card-images {
  display: flex;
  justify-content: center;
}

.project-images-list li {
  list-style-type: none;
  display: inline-block;
  padding: 0px 20px 0px 20px;; 
}

.project-links li a{
  text-decoration: none;
  color: black;
  border: 2px solid #000;
  padding: 2% 15% 2% 15%;
  border-radius: 20px;
}

.project-links li a:hover {
  background-color: #A9C9FF;
  color: #000;
  transition: 500ms;
}

.project-card-contribution{
  border: #A9C9FF 2px solid;
  padding: 2% 2% 2% 2%;
  border-radius: 20px;
}